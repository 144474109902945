<template>
  <div>
    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="O time de futebol"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Assim como nas demais comunidades do território de Mariana, o
                time de futebol de Bento Rodrigues, o Unidos de Bento Rodrigues
                (U.B.R.) fazia parte das atividades de socialização e união
                entre os moradores. Depois do rompimento da barragem, com a
                destruição do território e a quebra de laços afetivos, o time
                feminino de futebol de Bento Rodrigues assume também a condição
                de resistência para manter os vínculos com o antigo território,
                que fazia parte de sua história.
              </p>
              <p>
                Entretanto, enfrenta entre outros desafios, dificuldades para
                obter mais investimentos e manter a prática do esporte, que
                representa hoje conforto e força para as mulheres da equipe,
                incluindo a responsável pelo time, Larissa Sena.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-end align-md-end"
        >
          <v-img
            max-width="70%"
            src="../../../assets/sobre_bento_time_futebol_01.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:70%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Larissa Sena, atingida de Bento Rodrigues e responsável pelo time de
            futebol feminino da comunidade. Fonte: Jornal A Sirene, Ed. 58,
            fevereiro de 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-start align-md-start"
        >
          <v-img
            max-width="70%"
            src="../../../assets/sobre_bento_time_futebol_02.png"
            alt=""
          ></v-img>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Na função de estar à frente da equipe, Larissa tem um projeto
                para melhorar a organização do time, bem como a estrutura de
                treinamento, equipamentos e transporte. Com a situação da
                pandemia, os treinos foram interrompidos, dificultando ainda
                mais os encontros e o restabelecimento do contato e das trocas
                que possuíam antes do rompimento, já que era na prática do
                futebol que as atletas resgatavam os momentos de convívio na
                comunidade de Bento Rodrigues.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p><strong>Referências Bibliográficas</strong></p>

            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento"
                class="pink--text"
                >https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento</a
              >. Acesso em: 30 mar. 2022.
            </p>
            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda. Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão. Mariana, 2017.
            </p>
            <p>
              TEIXEIRA, Vanessa Cerqueira. Fé e cultura barroca sob o manto
              mercedário: devoção, religiosidade e vivências a partir da
              irmandade de Nossa Senhora das Mercês de Mariana (1749-1822). 220
              f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
